import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getImage } from 'gatsby-plugin-image';

import TopSection from '../components/topSection';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { PACDisclaimer } from '../components/disclaimers';
import shortcodes from '../components/mdxCommon';
import { SignupForm } from '../components/signup';

const DefaultTemplate = ({ location = {}, data = {} }) => {
  const { search = '' } = location;
  const { mdx = {}, site = {} } = data;
  const {
    slug: slug,
    frontmatter: {
      is_pac: isPACFromData,
      featured_image: featuredImage,
      hide_featured_image: hideFeaturedImage,
      hide_top: hideTop,
      open_graph_image: openGraphImage,
      featured_image_credit_link: creditLinkFromData,
      featured_image_credit: creditLinkTextFromData,
      sub_title: subTitleFromData,
      date,
      date_modified: dateModifiedFromData,
      title,
      description,
      hero_image_alt: heroImageAlt,
    } = {},
    body,
  } = mdx;

  const queryParam = new URLSearchParams(search);
  const isPAC = queryParam.get('ref') === 'pac' || isPACFromData;
  const heroImage = featuredImage && !hideFeaturedImage ? getImage(featuredImage) : null;
  const showTop = !hideTop;
  const openGraphImageResized = openGraphImage?.childImageSharp.resize || null;
  const creditLink = creditLinkFromData || null;
  const creditLinkText = creditLinkTextFromData || null;
  const subTitle = subTitleFromData || null;
  const dateModified = dateModifiedFromData;
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={openGraphImageResized}
        pathname={location.pathname}
      />
      <div className="mt-10 px-5 mb-20">
        {showTop && (
          <TopSection
            title={title}
            subTitle={subTitle}
            date={date}
            dateModified={dateModified}
            heroImage={heroImage}
            heroImageAlt={heroImageAlt}
            creditLink={creditLink}
            creditLinkText={creditLinkText}
          />
        )}

        <div
          className="default relative mx-auto mt-6 prose sm:prose-sm md:prose-base lg:prose-lg sm:mt-12 tracking-wide"
          data-js-body
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
          <div className="flex flex-col justify-center items-center bg-brand-blue-1 p-4 mb-6">
            <span className="mb-2">
              <b>Sign up for the GrowSF Report!</b>
            </span>
            <SignupForm formStyle="home" />
          </div>
        </div>
        {isPAC ? <PACDisclaimer /> : ''}
      </div>
    </Layout>
  );
};

export default DefaultTemplate;
